import service from "./FetchInterceptor";
import {baseUrl} from "../util/api_constant";

const HEADER: any = {'public-request': 'true'};
class AuthService{
	public static login(email: string, password: string){
		return service({
			url: baseUrl + '/auth',
			method: 'post',
			headers: HEADER,
			data: {username: email, password: password}
		}).then(response => response)
	}
	public static signup(data: any) {
		return service({
			url: baseUrl + '/auth/accessToken/register',
			method: 'post',
			headers: HEADER,
			data: data
		})
	}
}

export default AuthService
